import {
  CREATE_MEMBERSHIP,
  FIND_MEMBERSHIP, GET_MEMBERSHIP_LIST,
  SET_MEMBERSHIP_ERROR, SWITCH_MEMBERSHIP_STATE,
  UPDATE_MEMBERSHIP
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  methods: {
    deleteMembership(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/membership/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_MEMBERSHIP_ERROR);
          reject(response);
        })
      })
    },
    createMembership(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_MEMBERSHIP, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateMembership(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_MEMBERSHIP, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findMembership(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_MEMBERSHIP, id)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getMembershipList(page = 1, title = ''){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_MEMBERSHIP_LIST, { page, title })
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    switchMembershipState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_MEMBERSHIP_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    }
  }
}
