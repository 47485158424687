<template>
  <div class="create-film">
    <error :errors="membershipError" />
    <membership-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import MembershipForm from "../../../components/membership/MembershipForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { MembershipForm, Error },
    computed: {
      ...mapGetters(['membershipError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "VIP ئەزالىق تۈرى باشقۇرۇش", route: "list" },
        { title: "يېڭىدىن قوشۇش" }
      ]);
    }
  }
</script>
